<template>
  <section class="section-form" id="contacto">
    <div class="container">
      <div class="row" >
        <div class="col px-4">
          <div class="w-75 mx-auto">
            <div class="box-contact" style="text-align: start;">
              <div class="heading-title-element">
                <p>Solicita tu demostracion</p>
              </div>
              <div>
                <h2 class="heading-info-contact">¡Contáctanos!</h2>
              </div>
              <div>
                <p>Estamos listos para atenderte</p>
              </div>
              <div class="section-info">
                <section>
                  <div class="col ">

                  </div>
                  <div class="col">
                    <div class="row row-element">
                      <div class="heading-title-element">Operaciones</div>
                      <div class="element-content">
                        Perú - Ecuador - Colombia - México - Argentina
                      </div>
                      <div class="heading-title-element">Proximamente</div>
                      <div class="element-content">
                        Chile - USA - España
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div class="col">

                  </div>
                  <div class="col">
                    <div class="row row-element">
                      <div class="heading-title-element">
                        Contacto comercial
                      </div>
                      <div class="element-content">900002510</div>
                    </div>
                  </div>
                </section>
                <section>
                  <div class="col">

                  </div>
                  <div class="col">
                    <div class="row row-element">
                      <div class="heading-title-element">
                        Contacto de Operaciones
                      </div>
                      <div class="element-content">900080930</div>
                    </div>
                  </div>
                </section>
                <section>
                  <div class="col">

                  </div>
                  <div class="col">
                    <div class="row row-element">
                      <div class="heading-title-element">Escribenos</div>
                      <div class="element-content">comercial@validate.pe</div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="col px-4" v-if="mostrarComponente">
          <template >
            <form-demo  />
          </template>
        </div>
      </div>
    </div>
      
    
  </section>
</template>
<script>
export default {
  name: "InfoContact",
  components: {
    "form-demo": require("../../views/FormDemo.vue").default,
  },
  data(){
    return {
      
    }
  },
  computed:{
    mostrarComponente(){
      const rutasPermitidas = ['/'];
      return rutasPermitidas.includes(this.$route.path);
    }
  }
};
</script>
<style>
.section-form {
  background-color: #EE3C5F;
  padding: 40px 0;
  border-radius: 0px 50px 0px 50px;
  color: #fff;
}
.section-info{
  size: 20px;
}


.box-contact{
  margin-top: 24px;
}
.row-element {
  display: flex;
  flex-direction: column;
  
}
.heading-info-contact {
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
}
.heading-title-element {
  color: #ffc057;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}
.element-content {
  text-align: left;
  color: #ffffff;
  font-size: 16px;
}
</style>

<template>
    <v-content class="main-body">
          
          <main-body />
         <!--  <resumen-blog /> -->
          
    </v-content>
</template>
<script>
export default {
  name: 'HomePageComponent',
  components: {
    
    "main-banner": require("../components/HomeBanner.vue").default,
    "form-demo": require("../views/FormDemo.vue").default,
    "info-contact": require("../components/sections/Contact.vue").default,
    "resumen-blog": require("../components/sections/ResumenBlog.vue").default,
    "main-body": require("../home/MainBody.vue").default
  }
  
}
</script>
<style>
.main-body{
  font: "Poppins", sans-serif;
}
</style>
<template>
    <section id="eight-section" class="p-4">
      <div class="container">
        <div class="row">
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>

        </div>
        <div class="row">
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>

        </div>
        <div class="row">
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>
          <div class="col"><img src="../../assets/images/logo_sky.png" width="200px" class="m-3"></div>

        </div>
      </div>
    </section>
</template>
<script>
export default{
  name: "CustomerComponent",
};
</script>
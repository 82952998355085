<template>
  <section class="blog-container">
    <div class="container">
      <div class="row">
      <div class="col">
        <div class="box-container">
          <div class="blog-containt">
            <div class="mx-auto w-50">
              <p class="heading-subtitle">Blog</p>
              <h3 class="heading-title">
                Información de valor para la gestión del talento humano
              </h3>
              <div class="row">
                <div class="d-flex justify-content-center justify-content-md-start">
                  <a href="#" class="link-btn"> Conoce más </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex">
        <div class="box-container">
          <!-- <div class="card">
            <img
              src="../../assets/images/inicio/img-card-1.jpg"
              alt="Avatar"
              class="img-thumbnail"
              style="width: 100%"
            />
            <div class="container-card">
              
              <a>¿Cómo establecer y medir los indicadores de Talento Humano?</a>
            </div>
          </div> -->
          <div class="card" >
            <img src="https://globalwork.co/wp-content/uploads/2023/03/jason-goodman-Oalh2MojUuk-unsplash-300x169.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              
              <p class="card-text fs-5">
                ¿Cómo establecer y medir los indicadores de Talento Humano?
              </p>
              <a href="#" class="btn btn-primary">Leer más</a>
            </div>
          </div>
        </div>
         <div class="box-container">
          <!-- <div class="card">
            <img
              src="../../assets/images/inicio/img-card-2.jpg"
              alt="Avatar"
              class="img-thumbnail"
              style="width: 100%"
            />
            <div class="container-card">
              <a>Tendencias de Talento Humano en 2023</a>
            </div>
          </div> -->
          <div class="card" >
            <img src="https://globalwork.co/wp-content/uploads/2023/02/pexels-polina-zimmerman-3747446-300x169.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              
              <p class="card-text fs-5">
                Tendencias de Talento Humano en 2023
              </p>
              <a href="#" class="btn btn-primary">Leer más</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  </section>
</template>
<script>
export default {
  name: "resumen-blog",
};
</script>
<style>
.blog-container {
  background-color: #fcf8ff;
  padding: 32px 24px 32px 24px;
  position: relative;
  height: auto;
}
.blog-containt {
  text-align: left;
}
.row-container {
  display: flex;
  flex-direction: row;

  margin-left: 10%;
  margin-right: 10%;
}
.col-container {
  flex-direction: column;
  height: 100%;
  display: flex;
  flex: 1;
}

.box-container {
  
  display: flex;
  
  justify-content: end;
}
.group-card {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
@media (max-width: 767px) {
  .row-container {
    display: flex;
    flex-direction: column;
  }
  .group-card {
    display: flex;
    flex-direction: column;
  }
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container-card {
  padding: 2px 16px;
}

.btn-container {
  display: flex;
  @media(max-width: 767px){
    flex-direction: ;
  }
}
.link-btn {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #5f0091;
  height: auto;
  background: #ffc057;
  border-style: solid;
  border-width: 1.7px 1.7px 1.7px 1.7px;
  border-color: #ffc057;
  border-radius: 5px 5px 5px 5px;
  padding: 10px 20px;
  transition: all 0.5s;

  @media(max-width: 767px){
    font-size: 13px;
  }
}
.link-btn:hover {
  color: #fff;
  background-color: #EE3C5F;
}
</style>

<template>
    <div class="banner py-5" style="margin-top: 50px;">
        <div class="container" style="margin-top: 150px;">
            <div class="grid">
                <div class="item-1">
                    <div class="box my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">Servicios para el Bienestar Laboral</p>
                        <h3 class="heading-title" style="color: #fff;">Soporte Emocional Empresarial</h3>
                        <div class="widget-containt" style="color: #fff;">
                            Conocemos la importancia de que tus empleados se sientan escuchados, 
                            por eso desarrollamos un espacio en el que 
                            pueden compartir sus emociones con un profesional en Psicología.
                        </div>

                    </div>
                </div>
                <div class="item-2">
                    <form-demo />
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">
                        <img decoding="async" width="400" height="400" />

                    </div>
                </div>
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Acerca del servicio
                        </p>
                        <h1 class="heading-title" style="color: #411A56">
                            Una línea de atención psicológica que escucha a tu equipo
                        </h1>
                        <div class="widget-containt" style="color: #411A56">
                            Acompañar a tus empleados con una voz amiga y en la que puedan confiar 
                            tiene un impacto significativo en sus vidas y en sus familias. 
                            Pero además trae grandes beneficios para tu compañía, 
                            como mejoras en la productividad y disminución del ausentismo y la rotación.
                        </div>
                        <br />
                        <div class="widget-containt" style="color: #411A56">
                            En nuestra línea experta escuchamos y guiamos a tus colaboradores 
                            y construimos informes generales de la salud emocional de tu empresa.
                        </div>
                        
                        <br />
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="background-color: #FCF8FF;">
        <div class="container" style="color: #411A56">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Beneficios
                        </p>
                        <h1 class="heading-title" >
                            Información en profundidad de tus colaboradores
                        </h1>
                        <div class="widget-containt" >
                            Empleados felices y saludables construyen empresas competitivas, eficientes y capaces de adaptarse a los retos del mercado.
                        </div>
                        <br />
                        <ul style="list-style-type: none;">
                            <li class="widget-containt" >Más de 200 profesionales en Psicología.
                            </li>
                            <li class="widget-containt" >Atención inmediata a tus colaboradores.
                            </li>
                            <li class="widget-containt" >Paquetes de acompañamiento a tu medida.
                            </li>
                            <li class="widget-containt" >Informes dinámicos de la salud emocional de tu compañía.
                            </li>
                        </ul>
                        <br />

                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">

                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div style="background-color: #EE3C5F;padding: 48px 0px;">
        <div class="container">
            <p class="heading-subtitle" style="color: #ffc057">¿Cómo funciona?</p>
            <h1 class="heading-title" style="color: #fff">
                La manera más fácil y rápida de contratar el mejor talento humano
            </h1>
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">01.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Atención inmediata
                            para tus colaboradores:
                        </p>
                        <p class="widget-containt" style="color: #fff">
                            Contamos con psicólogos listos para escuchar y guiar a tu empleados.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">02.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Seguimiento de cada caso:
                        </p>
                        <p class="widget-containt" style="color: #fff">Nuestros profesionales hacen llamadas de acompañamiento
                            para conocer la evolución del colaborador.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">03.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start mb-1" style="color: #ffc057; padding: 20px 0">
                            Informes para la compañía:
                        </p>
                        <p class="widget-containt" style="color: #fff">Creamos informes dinámicos 
                            (sin referir casos particulares de empleados) 
                            para que conozcas la salud emocional de la compañía.</p>
                    </div>
                </div>
            
        </div>
    </div>
    <customers-images />
    <info-contact />
</template>
<script>
export default {
    name: "FourthComponent",
    components: {
        "info-contact": require("../sections/Contact.vue").default,
        "form-demo": require("../../views/FormDemo.vue").default,
        "customers-images": require("../../components/sections/Customers.vue").default
    },
    data() {
        return {
            mostrarComponente: false
        }
    },
};
</script>
<style></style>
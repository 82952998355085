import { createRouter, createWebHistory } from 'vue-router'

//Components
import HomePageComponent from "@/home/HomePage"
import FirstServiceComponent from "@/components/services/FirstService.vue"
import SecondServiceComponent from "@/components/services/SecondService.vue"
import ThirdServiceComponent from "@/components/services/ThirdService.vue"
import FourthServiceComponent from "@/components/services/FourthService.vue"
import FifthServiceComponent from "@/components/services/FifthService.vue"
import SixthServiceComponent from "@/components/services/SixthService.vue"
import SeventhServiceComponent from "@/components/services/SeventhService.vue"
import EighthServiceComponent from "@/components/services/EighthService.vue"

const routes = [
    { path: '/' , component : HomePageComponent},
    { path: '/servicios/visitas-domiciliarias' , component : FirstServiceComponent},
    { path: '/servicios/estudios-de-seguridad' , component : SecondServiceComponent},
    { path: '/servicios/verificacion-de-antecedentes' , component : ThirdServiceComponent},
    { path: '/servicios/soporte-emocional-empresarial' , component : FourthServiceComponent},
    { path: '/servicios/visitas-domiciliarias-teletrabajo' , component : FifthServiceComponent},
    { path: '/servicios/pruebas-de-poligrafia' , component : SixthServiceComponent},
    { path: '/servicios/verificacion-de-referencias-laborales' , component : SeventhServiceComponent},
    { path: '/servicios/verificacion-de-referencias-academicas' , component : EighthServiceComponent},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
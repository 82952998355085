<template>
    <div class="banner py-5" style="margin-top: 50px;">
        <div class="container" style="margin-top: 150px;">
            <div class="grid">
                <div class="item-1">
                    <div class="box" style="text-align: start;">
                        <p class="heading-subtitle" style="color: #ffc057">Soluciones</p>
                        <h3 class="heading-title" style="color: #fff;">Soporte Emocional Empresarial</h3>
                        <div class="widget-containt" style="color: #fff;">
                            Conocemos la importancia de que tus empleados se sientan escuchados, 
                            por eso desarrollamos un espacio en el que 
                            pueden compartir sus emociones con un profesional en Psicología.
                        </div>

                    </div>
                </div>
                <div class="item-2">
                    <form-demo />
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">
                        <img decoding="async" width="400" height="400" />

                    </div>
                </div>
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Acerca del servicio
                        </p>
                        <h1 class="heading-title" style="color: #411A56">
                            Visitas Domiciliarias Virtuales para el Teletrabajo 
                        </h1>
                        <div class="widget-containt" style="color: #411A56">
                            <strong>¿Conoces las condiciones en las que trabajan tus colaboradores remotos?</strong> 
                            Tener un espacio de trabajo seguro y bien equipado es fundamental 
                            para preservar el bienestar del talento humano.
                        </div>
                        <br />
                        <div class="widget-containt" style="color: #411A56">
                            Revisamos las condiciones de tus teletrabajadores 
                            y te entregamos una lista de chequeo y recomendaciones para cada colaborador.
                        </div>
                        <br/>
                        <ul style="list-style-type: none;">
                            <li class="widget-containt" >Visitas realizadas por profesionales en Psicología y Trabajo Social.
                            </li>
                            <li class="widget-containt" >En cumplimiento de la Ley 1221 de 2008 y el Decreto 1227 de 2022 del Ministerio Colombiano de Trabajo
                            </li>
                            <li class="widget-containt" >En cumplimiento de los requerimientos de Salud Ocupacional y SST
                            </li>
                            <li class="widget-containt" >Contacto completamente virtual con tus empleados y resultados en tiempo récord y en la nube.
                            </li>
                        </ul>
                        <br />
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="background-color: #FCF8FF;">
        <div class="container" style="color: #411A56">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            ¿Qué revisamos?
                        </p>
                        <h1 class="heading-title" >
                            Un análisis para el teletrabajo seguro y productivo
                        </h1>
                        <div class="widget-containt" >
                            Nuestros profesionales en Psicología y Trabajo Social interactúan con tu talento humano y revisan lo necesario para un trabajo adecuado:
                        </div>
                        <br />
                        <ul style="list-style-type: none;">
                            <li class="widget-containt" >Condiciones locativas: ruido, iluminación, seguridad física de la construcción, etc.
                            </li>
                            <li class="widget-containt" >Ergonomía: sillas, escritorios, soportes para computadores, etc.
                            </li>
                            <li class="widget-containt" >Conectividad: idoneidad de las conexiones eléctricas y calidad de la red de internet.
                            </li>
                            <li class="widget-containt" >Contexto sociofamiliar: personas con las que convive el aspirante y factores que facilitan o afectan el trabajo desde casa.
                            </li>
                        </ul>
                        <br />

                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">

                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div style="background-color: #EE3C5F;padding: 48px 0px;">
        <div class="container">
            <p class="heading-subtitle" style="color: #ffc057">¿Cómo funciona?</p>
            <h1 class="heading-title" style="color: #fff">
                La manera más fácil y rápida de verificar las condiciones de tus teletrabajadores
            </h1>
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">01.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Cuéntanos de tu empleado:
                        </p>
                        <p class="widget-containt" style="color: #fff">
                            Compártenos información de tu candidato a través de nuestra plataforma. Toma menos de un minuto.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">02.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Realizamos la visita:
                        </p>
                        <p class="widget-containt" style="color: #fff">Agendamos y hacemos una visita virtual en la vivienda del candidato, con enfoque sociofamiliar y de SST.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">03.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Revisa la información en tiempo real:
                        </p>
                        <p class="widget-containt" style="color: #fff">Obtén un informe detallado de nuestros hallazgos a través de la plataforma de información de Validate.</p>
                    </div>
                </div>
            
        </div>
    </div>
    <customers-images />
    <info-contact />
</template>
<script>
export default {
    name: "FifthComponent",
    components: {
        "info-contact": require("../sections/Contact.vue").default,
        "form-demo": require("../../views/FormDemo.vue").default,
        "customers-images": require("../../components/sections/Customers.vue").default
    },
    data() {
        return {
            mostrarComponente: false
        }
    },
};
</script>
<style></style>
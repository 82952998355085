<template>
  <section class="banner py-5" style="margin-top: 48px">
    <div class="banner-container">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="box" style="text-align: start; margin-top: 20%">
              <h1 class="text-h1">
                Construimos vínculos confiables entre las empresas y sus
                candidatos
              </h1>
              <h2 class="text-h2">
                Estudios de seguridad, visitas domiciliarias y verificación de
                antecedentes en tiempo récord en Latinoamérica.
              </h2>
              <div class="row">
                <div class="col">
                  <!-- Button trigger modal -->
                  <button type="button" class="btn link-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Solicitar demostracion
                  </button>
                </div>
                <div class="col">
                  <p>Leer más acerca de nosotros</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        <form-demo />
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "MainBanner",
  components: {
    "form-demo": require("../views/FormDemo.vue").default, 
  }
};
</script>
<style>
.banner {
  min-height: 950px;
  display: flex;
  /* background-image: url("../assets/images/inicio/banner.png"); */
  background-image: url(https://globalwork.co/wp-content/uploads/2022/03/portada-globalwork.png);
  
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.banner-container {
  /*   background-color: transparent;
  background-image: linear-gradient(0deg, #5f0091 100%, #48255a 91%); */
  width: 100%;
}

@media (max-width: 750px) {
  .banner {
    width: 100%;
  }
}

.element-row {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
}

.element-col {
  position: relative;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  text-align: left;
}

.box {
  color: #fff;
  width: 66.66%;
  position: relative;
  padding: 24px 0;
  @media(max-width: 767px){
    width: 100%;
  }
}

.text-h1 {
  font-size: 46px;
  font-weight: bold;
  line-height: 58px;
}

.text-h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}
</style>

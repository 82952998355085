<template>
  <main-banner />
  <section id="Body">
    <section id="first-section" style="padding-top: 130px;padding-bottom: 40px;">
      <div class="container">
        <div class="grid mx-auto d-row">
          <div class=" item item-1" style="
                  padding: 30px;
                  text-align: left;
                  margin-left: auto;
                  margin-right: auto;
                ">
            <div style="">
              <p class="heading-subtitle">Conoce nuestros servicios</p>
              <h3 class="heading-title">
                Soluciones desarrolladas para optimizar tus procesos de selección
              </h3>
              <div class="widget-containt">
                Un equipo de tecnología y cientos de profesionales en
                Latinoamérica trabajan para proporcionarte información confiable.
              </div>
            </div>
          </div>
          <div class=" item item-2" style="
                  padding: 30px;
                  text-align: left;
                  margin-left: auto;
                  margin-right: auto;
                ">
            <p class="heading-subtitle hs-bold" style="color: #48255a">
              Visitas Domiciliarias
            </p>
            <div class="widget-containt">
              Interactuamos con los aspirantes y su grupo familiar para conocer
              sus fortalezas y el potencial que podrán desarrollar en la
              organización.
            </div>
            <div class="d-flex justify-content-center justify-content-md-start">
              <a href="#" class="link-btn">Conoce más</a>
            </div>
            <p class="heading-subtitle hs-bold" style="color: #48255a">
              Estudios de Seguridad
            </p>
            <div class="widget-containt">
              Revisamos anotaciones judiciales, referencias personales, laborales
              e información académica para generar un vínculo confiable.
            </div>
            <div class="d-flex justify-content-center justify-content-md-start">
              <a href="#" class="link-btn">Conoce más</a>
            </div>
            <p class="heading-subtitle hs-bold" style="color: #48255a">
              Verificación de Antecedentes
            </p>
            <div class="widget-containt">
              Verificamos la validez de los datos a partir de registros oficiales
              y certificados para que tomes decisiones acertadas.
            </div>
            <div class="d-flex justify-content-center justify-content-md-start">
              <a href="#" class="link-btn">Conoce más</a>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section id="second-section" class="p-3 " style="background-color: #fcf8ff; padding-bottom: 100px !important;">
      <div class="container">
        <div class="grid mx-auto" style="margin-left: 5%; margin-right: 5%">
          <div class="item item-1">
            <div style="padding: 10px">
              <div class="grid p-4">
                <div class="item item-1">
                  <img decoding="async" src="https://globalwork.co/wp-content/uploads/2020/05/icon-contigo-hoy-home.svg"
                    class="attachment-full size-full wp-image-5779" width="120px" alt="" loading="lazy" />
                </div>
                <div class="item item-2">
                  <p class="heading-subtitle hs-bold" style="color: #48255a">
                    Soporte Emocional Empresarial
                  </p>
                  <div class="widget-containt">
                    Una línea de atención psicológica en la que nuestros
                    profesionales brindan soporte emocional a tus colaboradores.
                  </div>
                  <div class="d-flex justify-content-center justify-content-md-start">
                    <a href="#" class="link-btn">Conoce más</a>
                  </div>
                </div>
              </div>
              <div class="grid p-4">
                <div class="item item-1">
                  <img decoding="async"
                    src="https://globalwork.co/wp-content/uploads/2020/05/icon-visitas-domiciliarias-home.svg"
                    class="attachment-full size-full wp-image-5785" width="120px" alt="" loading="lazy" />
                </div>
                <div class="item item-2">
                  <p class="heading-subtitle hs-bold" style="color: #48255a">
                    Visitas Domiciliarias Virtuales para el Teletrabajo
                  </p>
                  <div class="widget-containt">
                    Verificamos que tus empleados remotos cuenten con las
                    condiciones para realizar un trabajo seguro y productivo.
                  </div>
                  <div class="d-flex justify-content-center justify-content-md-start">
                    <a href="#" class="link-btn">Conoce más</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="item item-2" style="
                    display: grid;
                    grid-template-columns: 100%;
                    grid-template-rows: 33.33% 33% 33%;
                  ">
            <br>
            <br>
            <div style="">
              <p class="heading-subtitle">Conoce nuestros servicios</p>
              <h3 class="heading-title">Servicios de Bienestar Laboral</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="thirth-section">
      <div class="grid">
        <div class=""></div>
        <div class=""></div>
      </div>
    </section>
    <section id="fourth-section" class="section-form">
      <div class="container">
        <div class="row mx-auto" style="padding: 1rem 2rem">
          <div class="col">
            <div class="my-text-align">
              <p class="heading-subtitle" style="color: #ffc057">
                Acerca de Nosotros
              </p>
              <h1 class="heading-title" style="color: white">
                Inteligencia para la selección del mejor talento humano
              </h1>
              <div class="widget-containt" style="color: white">
                En <strong>{{ nombreEmpresa }}</strong> nos alineamos con las áreas de gestión humana de
                las compañías latinoamericanas para optimizar sus procesos de
                contratación y generar vínculos confiables con su talento
                humano.
              </div>
              <br />
              <div class="widget-containt" style="color: white">
                Con nuestra tecnología tendrás todos tus procesos de
                verificación de información seguros en la nube, para que los
                revises en cualquier momento.
              </div>
              <br />
              <br />
              <div class="d-flex justify-content-center justify-content-md-start">
                <a href="#" class="link-btn"> Solicitar demostracion </a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="w-75 mx-auto" style="position: relative;" >
              <img decoding="async" width="400" height="400"
                src="../assets/images/fonts/human_1.png"
                 style="margin-right: 8rem;position: absolute;left: 200px;" />
              <img decoding="async" width="400" height="400"
                src="../assets/images/fonts/human_2.png"
                style="margin-right: 8rem; position: absolute;top:150px;left: 0;" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="fifth-section" class="p-5">
      <div class="container">
        <div class="row mx-auto">
          <div class="col d-col">
            <img decoding="async" src="https://globalwork.co/wp-content/uploads/2020/05/image1-herramientas-home.svg"
              class="container" alt="" loading="lazy" />
          </div>
          <div class="col" style="text-align: left; padding: 4px">
            <div class="row align-items-center">
              <div class="col">
                <p class="heading-subtitle">Nuestras Herramientas</p>
                <h3 class="heading-title">
                  Soluciones inteligentes, resultados confiables y en tiempo
                  real
                </h3>
                <div class="widget-containt">
                  Contamos con una plataforma amigable que te proporciona
                  información precisa sobre todos los candidatos de tu proceso
                  de selección.
                </div>
                <div class="d-col">
                  <p class="heading-subtitle">
                    Toda la informacion disponble en nuestra plataforma
                  </p>
                  <div class="widget-containt">
                    Contamos con una plataforma amigable que te proporciona
                    información precisa sobre todos los candidatos de tu proceso
                    de selección.
                  </div>
                  <p class="heading-subtitle">Procesos en tiempo real</p>
                  <div class="widget-containt">
                    Optimizamos la verificación de información hasta en un 80%.
                  </div>
                  <p class="heading-subtitle">
                    Cobertura en todo el territorio
                  </p>
                  <div class="widget-containt">
                    Nuestros profesionales llegan a las ciudades que necesitas
                    para entregarte resultados rápidos y confiables.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sixth-section" class="section-form">
      <div class="container">
        <div class="row">
          <div class="col d-col">
            <p class="heading-subtitle" style="color: #ffc057">
              Nuestros Resultados
            </p>
            <h1 class="heading-title text-center  mx-auto" style="color: white">
              Información que impacta la contratación de las empresas
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="element-containt">
              <div class="number-heading-subtitle">1K+</div>
              <p class="heading-subtitle" style="color: white; padding: 20px 0">
                Visitadores domiciliarios en toda la región
              </p>
            </div>
          </div>
          <div class="col">
            <div class="element-containt">
              <div class="number-heading-subtitle">800K+</div>
              <p class="heading-subtitle" style="color: white; padding: 20px 0">
                Verificaciones realizadas
              </p>
            </div>
          </div>
          <div class="col">
            <div class="element-containt">
              <div class="number-heading-subtitle">120+</div>
              <p class="heading-subtitle" style="color: white; padding: 20px 0">
                Clientes felices con los resultados
              </p>
            </div>
          </div>
        </div>
        <div class="container text-center">
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <div class="container text-center">
                <div class="d-flex justify-content-center justify-content-md-start">
                  <a href="#" class="link-btn"> Solicitar demostracion </a>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section id="seventh-section">
      <div class="container">
        <div class="row">
          <div class="col d-col">
            <p class="heading-subtitle">Casos de exito</p>
            <h3 class="heading-title w-50 mx-auto">
              Contamos con el respaldo de nuestros clientes
            </h3>
          </div>
        </div>
        <br />
        <br />
        <div class="box-video">
          <iframe
            src="https://www.youtube.com/embed/KIchlMszUiE"
            title="YouTube video player"
            frameborder="0"
            width="100%"
            height="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section> -->
    <customers-images />
  </section>
  <info-contact />
</template>
<script>

export default {
  name: "MainBody",
  components: {
    "main-banner": require("../components/HomeBanner.vue").default,
    "info-contact": require("../components/sections/Contact.vue").default,
    "customers-images": require("../components/sections/Customers.vue").default
  },
  data() {
    return {
      nombreEmpresa: "Validate",
      mostrarComponente: true
    }
  }
};
</script>
<style>
.bg-color {
  background-color: #ffc057;
}

#sixth-section {
  background: #ee3c5f;
  padding: 100px 0;
}

#seventh-section {
  padding: 100px 0;
}

.heading-title {
  color: #48255a;
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
}

.heading-subtitle {
  color: #5f0091;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.my-text-align{
  text-align: left; 
  margin-top: 20px;

  @media (max-width: 767px) {
    text-align: center; /* Centra el texto en pantallas móviles */
}
}

.hs-bold {
  font-weight: bold;
}

.widget-containt {
  text-align: left;
  color: #411a56;
  font-size: 17px;
  @media (max-width: 767px) {
    text-align: center; /* Centra el texto en pantallas móviles */
    font-size: 14px;
}
}

.number-heading-subtitle {
  color: #ffc057;
  font-size: 48px;
  font-weight: 800;
  line-height: 55px;
  padding: 20px 40px 10px 40px;
}

.mw-10 {
  margin-right: 10%;
  margin-left: 10%;
}

@media (min-width: 450px) and (max-width: 767px) {
  .d-row {
    display: flex;
    flex-direction: row;
  }

  .d-col {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.box-video {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 450px;
  position: relative;
}

@media (min-width: 920px) {
  .box-video {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: 750px;
    position: relative;
  }
}

@media (min-width: 767px) {
  .grid {
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 100%;
  }
}

.item-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  align-items: center;
}

.item-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
</style>

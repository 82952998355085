<template>
<main-header />
  <router-view></router-view>
  <!-- <HomeComponent />
   -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/>
 -->
 <main-footer />
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import HomeComponent from './components/Home.vue'


export default {
  name: 'App',
  components: {
    //HelloWorld,
    //HomeComponent
    "home-page": require("./home/HomePage.vue").default,
    "main-header": require("./components/MainHeader.vue").default,
    "main-footer": require("./views/Footer.vue").default,
    
    
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
</style>

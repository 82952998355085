<template>
    <div class="banner py-5" style="margin-top: 50px;">
        <div class="container" style="margin-top: 150px;">
            <div class="grid">
                <div class="item-1">
                    <div class="box" style="text-align: start;">
                        <p class="heading-subtitle" style="color: #ffc057">Soluciones</p>
                        <h3 class="heading-title" style="color: #fff;">Visitas Domiciliarias</h3>
                        <div class="widget-containt" style="color: #fff;">
                            Interactuamos con los aspirantes y su grupo familiar 
                            para conocer sus fortalezas y el potencial que podrán desarrollar en la organización.
                        </div>
                    </div>
                </div>
                <div class="item-2">
                    <form-demo />
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">
                        <img decoding="async" width="400" height="400" />

                    </div>
                </div>
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Soluciones para la selección de personal
                        </p>
                        <h1 class="heading-title" style="color: #411A56">
                            Visitas domiciliarias
                            para la contratación
                        </h1>
                        <div class="widget-containt" style="color: #411A56">
                            La visita domiciliaria es un espacio que brinda información fundamental
                            que no se aborda en entrevistas o pruebas técnicas.
                        </div>
                        <br />
                        <div class="widget-containt" style="color: #411A56">
                            Un profesional en Psicología o Trabajo Social visita el domicilio del aspirante
                            y comparte con su entorno familiar y social.
                        </div>
                        <div class="widget-containt" style="color: #411A56">
                            En nuestra plataforma recibirás un informe completo y en tiempo real de las capacidades y
                            factores de riesgo del candidato
                            para hacer un análisis predictivo de su desarrollo en la compañía.
                        </div>
                        <br />
                        <br />
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="background-color: #EE3C5F;">
        <div class="container">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Soluciones para el Teletrabajo
                        </p>
                        <h1 class="heading-title" style="color: white">
                            Visitas Domiciliarias
                            Virtuales para el Teletrabajo
                        </h1>
                        <div class="widget-containt" style="color: white">
                            ¡Si el trabajo se lleva a casa, la Seguridad y la Salud también!
                            Conoce las condiciones de tus colaboradores para un óptimo trabajo remoto.
                        </div>
                        <br />
                        <ul style="list-style-type: none;">
                            <li class="widget-containt" style="color: white">Visitas realizadas por profesionales en
                                Psicología y Trabajo Social.</li>
                            <li class="widget-containt" style="color: white">Análisis del entorno sociofamiliar y de SST.
                            </li>
                            <li class="widget-containt" style="color: white">Contacto a través de una videollamada en
                                nuestra plataforma</li>
                        </ul>
                        <br />

                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">
                        <img decoding="async" width="400" height="400" />

                    </div>
                </div>
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Beneficios
                        </p>
                        <h1 class="heading-title" style="color: #411A56">
                            Un análisis social y familiar de tu candidato
                        </h1>
                        <div class="widget-containt" style="color: #411A56">
                            Conocimiento profundo del aspirante y su contexto para
                            tomar decisiones de contratación acertadas.
                        </div>
                        <br />
                        <ul style="list-style-type: none;">
                            <li class="widget-containt">Análisis del entorno de tus aspirantes.</li>
                            <li class="widget-containt">Resultados en tiempo récord.</li>
                            <li class="widget-containt">Presencia en el territorio.</li>
                            <li class="widget-containt">Visitas presenciales o virtuales realizadas por profesionales en
                                Psicología y Trabajo Social.</li>
                        </ul>

                        <br />
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="background-color: #EE3C5F;padding: 48px 0px;">
        <div class="container">
            <p class="heading-subtitle" style="color: #ffc057">¿Cómo funciona?</p>
            <h1 class="heading-title" style="color: #fff">
                La manera más fácil y rápida de contratar el mejor talento humano
            </h1>
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">01.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Cuéntanos de tu candidato:
                        </p>
                        <p class="widget-containt" style="color: #fff">Compártenos información de tu candidato a través de nuestra plataforma. Toma menos de un minuto.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">02.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Realizamos la visita:
                        </p>
                        <p class="widget-containt" style="color: #fff">Agendamos y recorremos la vivienda del aspirante de forma presencial o virtual en tiempo récord en todo el territorio.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">03.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Revisa la información en tiempo real:
                        </p>
                        <p class="widget-containt" style="color: #fff">Obtén un informe detallado de nuestros hallazgos a través de la plataforma de información de Validate.</p>
                    </div>
                </div>
            
        </div>
    </div>
    <customers-images />
    <info-contact />
</template>
<script>
export default {
    name: "FirstComponent",
    components: {
        "info-contact": require("../sections/Contact.vue").default,
        "form-demo": require("../../views/FormDemo.vue").default,
        "customers-images": require("../../components/sections/Customers.vue").default
    },
    data() {
        return {
            mostrarComponente: false
        }
    },
};
</script>
<style></style>
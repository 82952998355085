<template>
  <section>
   
    <nav class="navbar  navbar-expand-lg fixed-top bg-body-tertiary color-header mb-5">
      <div class="container ">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="img-icon mx-4">
          <img src="../assets/images/fonts/logo-blanco.png" alt="" class="img-fluid">

        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-lg-auto ml-sm-0" style="text-align: start;">

            <li class="nav-item">
              <a class="nav-link heading-subtitle link-header" aria-current="page" href="/" target="_parent" style="color: white;">Inicio</a>
              <router-link to="/" class="nav-link heading-subtitle link-header" ></router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle heading-subtitle link-header" style="color: white;" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Servicios
              </a>
              <ul class="dropdown-menu">
                <li v-for="item in items" :key="item.id" class="py-2">
                  <router-link class="dropdown-item link-header" :to="item.url" style="cursor: pointer;">{{ item.name }}</router-link>
                </li>
               </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link heading-subtitle link-header" href="#fourth-section" style="color: white;">Sobre Nosotros</a>
            </li>
          </ul>
          <div class="d-flex justify-content-center justify-content-md-start">
            <a href="#first-section" class="link-btn">Conoce más</a>
          </div>
        </div>
      </div>
    </nav>
  </section>
</template>
<script>
export default {
  name: "MainHeader",
  data(){
    return{
      items: [
        {id: 1, name: "Visitas Domiciliarias", url: '/servicios/visitas-domiciliarias'},
        {id: 2, name: "Estudios de Seguridad", url: '/servicios/estudios-de-seguridad'},
        {id: 3, name: "Verificacion de Antecedentes", url: '/servicios/verificacion-de-antecedentes'},
        {id: 4, name: "Soporte Emocional Empresarial", url: '/servicios/soporte-emocional-empresarial'},
        {id: 5, name: "Visitas Domiciliarias para el Teletrabajo", url: '/servicios/visitas-domiciliarias-teletrabajo'},
        {id: 6, name: "Pruebas de Poligrafia", url: '/servicios/pruebas-de-poligrafia'},
        {id: 7, name: "Verificacion de referencias laborales", url: '/servicios/verificacion-de-referencias-laborales'},
        {id: 8, name: "Verificacion de Referencias Academicas", url: '/servicios/verificacion-de-referencias-academicas'},
      ]
    }
  }
};
</script>
<style>


.img-icon {
  width: 120px;
} 

@media (width: 767px) {
  .logo-mobile {
    width: 210px;
    height: 90px;
  }
  .img-icon{
    width: 70px;
  }
}


.color-header {
  background-color: #EE3C5F !important;
}

.link-header:hover{
  color: blueviolet !important;;
}
/* @media (max-width: 765px) {
  .header {
    width: auto;
  }
  .nav-bar {
    display: flex;
    flex-direction: column;
  }
} */
/* .nav-items {
  margin: 0 auto;
  padding: 0.5rem;
}
.list-items {
  list-style-type: none;
}
.items {
  float: left;
  text-align: center;
  width: 10rem;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
} */
</style>

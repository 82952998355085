<template>
    <div class="banner py-5" style="margin-top: 50px;">
        <div class="container" style="margin-top: 150px;">
            <div class="grid">
                <div class="item-1">
                    <div class="box" style="text-align: start;">
                        <p class="heading-subtitle" style="color: #ffc057">Soluciones</p>
                        <h3 class="heading-title" style="color: #fff;">Soporte Emocional Empresarial</h3>
                        <div class="widget-containt" style="color: #fff;">
                            Conocemos la importancia de que tus empleados se sientan escuchados, 
                            por eso desarrollamos un espacio en el que 
                            pueden compartir sus emociones con un profesional en Psicología.
                        </div>

                    </div>
                </div>
                <div class="item-2">
                    <form-demo />
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">
                        <img decoding="async" width="400" height="400" />

                    </div>
                </div>
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Acerca del servicio
                        </p>
                        <h1 class="heading-title" style="color: #411A56">
                            Poligrafías pre-empleo, de rutina y específicas
                        </h1>
                        <div class="widget-containt" style="color: #411A56">
                            Utilizamos tecnología de punta en la identificación de reacciones significativas de engaño 
                            para entregarte informes en tiempo récord y con alta confiabilidad.
                        </div>
                        <br />
                        <div class="widget-containt" style="color: #411A56">
                            Todos nuestros poligrafistas están certificados por la Asociación Latinoamericana de Poligrafistas.
                        </div>
                        <br/>
                        
                        <br />
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="background-color: #FCF8FF;">
        <div class="container" style="color: #411A56">
            <div class="row mx-auto" style="padding: 1rem 2rem">
                <div class="col">
                    <div class="my-text-align">
                        <p class="heading-subtitle" style="color: #ffc057">
                            Beneficios
                        </p>
                        <h1 class="heading-title" >
                            Información en profundidad de tus candidatos y colaboradores
                        </h1>
                        <div class="widget-containt" >
                            Toma las mejores decisiones en Talento Humano con profesionales certificados en poligrafía.
                        </div>
                        <br />
                        <ul style="list-style-type: none;">
                            <li class="widget-containt" >Cobertura en todo Perú.
                            </li>
                            <li class="widget-containt" >Resultados en tiempo récord.
                            </li>
                            <li class="widget-containt" >Informes disponibles en cualquier momento.
                            </li>
                        </ul>
                        <br />

                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a href="#" class="link-btn"> Solicitar demostracion </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="w-75 mx-auto" style="position: relative;">

                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div style="background-color: #EE3C5F;padding: 48px 0px;">
        <div class="container">
            <p class="heading-subtitle" style="color: #ffc057">¿Cómo funciona?</p>
            <h1 class="heading-title" style="color: #fff">
                La manera más fácil y rápida de verificar las condiciones de tus teletrabajadores
            </h1>
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">01.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Cuéntanos de tu empleado:
                        </p>
                        <p class="widget-containt" style="color: #fff">
                            Compártenos información de la persona a la que quieres aplicar la prueba a través de nuestra plataforma. 
                            Toma menos de un minuto.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">02.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Realizamos la prueba:
                        </p>
                        <p class="widget-containt" style="color: #fff">Agendamos a tu candidato o empleado y nuestros profesionales certificados por la Asociación Latinoamericana de Poligrafistas aplican la prueba.</p>
                    </div>
                </div>
            
            
                <div class="row justify-content-center">
                    <div class="col-5">
                        <div class="number-heading-subtitle text-end">03.</div>
                    </div>
                    <div class="col-5">
                        <p class="heading-subtitle text-start" style="color: #ffc057; padding: 20px 0">
                            Revisa la información en tiempo real:
                        </p>
                        <p class="widget-containt" style="color: #fff">Obtén un informe detallado de nuestros hallazgos a través de la plataforma de información de Validate.</p>
                    </div>
                </div>
            
        </div>
    </div>
    <customers-images />
    <info-contact />
</template>
<script>
export default {
    name: "SixthComponent",
    components: {
        "info-contact": require("../sections/Contact.vue").default,
        "form-demo": require("../../views/FormDemo.vue").default,
        "customers-images": require("../../components/sections/Customers.vue").default
    },
    data() {
        return {
            mostrarComponente: false
        }
    },
};
</script>
<style></style>
<template>
  <form class="card-demo container">
    <div class="row grid-container">
      <div class="row field-form">
        <div class="col col-sm-12">
          <label class="f-title">
            <span class="text-form">*Nombres</span>
          </label>
        </div>
        <div class="col col-sm-12">
          <input class="form-control" type="text" name="name" />
        </div>
      </div>
      <div class="row field-form">
        <div class="col col-sm-12">
          <label class="f-title">
            <span class="text-form">*Apellidos</span>
          </label>
        </div>
        <div class="col col-sm-12">
          <input class="form-control" type="text" name="lastname" />
        </div>
      </div>
    </div>
    <div class="row grid-container">
      <div class="row field-form">
        <div class="col">
          <label class="f-title">
            <span class="text-form">*Empresa</span>
          </label>
        </div>
        <div class="col">
          <input class="form-control" type="text" name="enterprise" />
        </div>
      </div>
      <div class="row field-form">
        <div class="col">
          <label class="f-title">
            <span class="text-form">*Tamaño empresa</span>
          </label>
        </div>
        <div class="col">
          <select name="numero_empleados" class="form-select" data-default-select-value="0">
            <option value="0" selected="selected">
              --Numero de empleados--
            </option>
            <option value="1 - 10">1 - 10</option>
            <option value="11 - 50">11 - 50</option>
            <option value="51 - 200">51 - 200</option>
            <option value="201 a 800">201 - 800</option>
            <option value="801 - 2000">801 - 2000</option>
            <option value="2001 - 3600">2001 - 3600</option>
            <option value="3601 - 5000">3601 - 5000</option>
            <option value="5001 - 10000">5001 - 10000</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row grid-container">
      <div class="row field-form">
        <div class="col">
          <label class="f-title">
            <span class="text-form">Email empresarial</span>
          </label>
        </div>
        <div class="col">
          <input class="form-control" type="mail" name="mail" />
        </div>
      </div>

      <div class="row field-form">
        <div class="col">
          <label class="f-title">
            <span class="text-form">*Celular</span>
          </label>
        </div>
        <div class="col">
          <input class="form-control" type="text" name="name" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="row field-form">
        <div class="col">
          <label class="f-title">
            <span class="text-form">¿Servicio de tu interes?</span>
          </label>
        </div>
        <div class="col">
          <div class="grid-container"> 
            <select name="servicio_interes" class="form-select" style="width: 100%;">
            <option value="0" selected="selected">--Escoge un Servicio</option>
            <option value="Estudios de Seguridad">Estudios de Seguridad</option>
            <option value="Visita Domiciliaria de Contratacion">
              Visita Domiciliaria de Contratacion
            </option>
            <option value="Visita Domiciliaria de Teletrabajo">
              Visita Domiciliaria de Teletrabajo
            </option>
            <option value="Verificaion de Antecedentes">
              Verificaion de Antecedentes
            </option>
            <option value="Soporte Emocional Empresarial">
              Soporte Emocional Empresarial
            </option>
            <option value="Poligrafia">Poligrafia</option>
            <option value="Verificacion de Referencias Academicas">
              Verificacion de Referencias Academicas
            </option>
            <!-- <option value="Visitas Domiciliarias OEA">Visitas Domiciliarias OEA</option> -->
          </select>
          </div>
          
        </div>
      </div>
    </div>
    <div class="row field-form">
      <div class="col">
        <div class="button-demo mx-auto">
          <span class="button-form-text"> Solicitar Demo </span>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
export default{
  name: "FormDemo",
};
</script>
<style>
.card-demo {
  background-color: #fff;
  width: 490px;
  border-radius: 50px 10px 50px 10px;
  padding: 40px 40px 20px 40px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.field-form {
  flex-direction: column;
  margin-bottom: 15px;
  height: 78px;
  display: flex;
}
@media (max-width: 767px) {
  .grid-container {
  display: flex;
  grid-template-columns: repeat(2, 50%);
  flex-direction: column;
  ;
}
}
.f-title {
  width: auto;
}

.text-form {
  font-size: 16px !important;
  color: #303350 !important;
  font-family: Montserrat !important;
  font-weight: 400 !important;
}
.button-demo {
  padding: 15px 35px;
  font-family: "Montserrat";
  border-color: rgb(255, 192, 87);
  background-color: rgb(255, 192, 87);
  color: rgb(95, 0, 145);
  border-radius: 6px;
  
}
.button-form-text {
  font-size: 16px !important;
  font-family: Montserrat;
  font-weight: 600;
}
</style>
